import { useState, useEffect } from 'react'

const TOAST_TIMEOUT = 3000

export function useToast() {
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts((prevToasts) => prevToasts.slice(1))
      }, TOAST_TIMEOUT)
      return () => clearTimeout(timer)
    }
  }, [toasts])

  function toast({ title, description, variant = 'default' }) {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), title, description, variant },
    ])
  }

  return { toasts, toast }
}
