import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from "./ui/button";

function PaymentConfirmationPage() {
  const location = useLocation();
  const { redemptionCode } = location.state || {};

  return (
    <div className="container mx-auto p-4 bg-green-50 min-h-screen flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full text-center">
        <h1 className="text-3xl font-bold text-green-600 mb-6">Payment Successful!</h1>
        <p className="text-lg mb-4">Thank you for your purchase.</p>
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Redemption Code:</h2>
          <p className="text-2xl font-bold text-green-700">{redemptionCode || 'Code not available'}</p>
        </div>
        <p className="mb-6 text-sm text-gray-600">
          You will receive this code via email shortly. If you entered the email address of the gift recipient, they will
          also receive this code via email on the specified date.
        </p>
        <Button
          className="w-full mt-4 bg-green-600 hover:bg-green-700 text-white py-3 text-lg"
          onClick={() => window.location.href = "https://farmbarn.com.au"}
        >
          Return to Farm Barn
        </Button>
      </div>
    </div>
  );
}

export default PaymentConfirmationPage;
