import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signOut } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCoIxwo5HZmSfHWuL_to60JSZz3qsnqxos",
    authDomain: "farmbarn-a29a7.firebaseapp.com",
    projectId: "farmbarn-a29a7",
    storageBucket: "farmbarn-a29a7.appspot.com",
    messagingSenderId: "1095734815752",
    appId: "1:1095734815752:web:197290f92fec261b5df5bd",
    measurementId: "G-0021L965EY"
  };

const initializeFirebase = async (retries = 3, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const analytics = getAnalytics(app);
      const functions = getFunctions(app);
      
      // Pre-warm specific functions
      await prewarmFunctions(functions);
      
      return { db, analytics, functions };
    } catch (error) {
      if (i === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
};

const prewarmFunctions = async (functions) => {
  try {
    // Create callable references
    const processPaymentFn = httpsCallable(functions, 'processPayment');
    const createGiftCardFn = httpsCallable(functions, 'createGiftCard');
    
    // Make minimal warm-up calls
    await Promise.all([
      processPaymentFn({ 
        warmup: true,
        paymentMethodId: 'warmup',
        amount: 0,
        purchaseType: 'warmup',
        customerInfo: {
          name: 'warmup',
          email: 'warmup@example.com',
          phone: '0000000000'
        }
      }),
      createGiftCardFn({ 
        warmup: true,
        transactionId: 'warmup-id',
        amount: 0
      })
    ]);
  } catch (error) {
    console.warn('Function pre-warming failed:', error);
    // Don't throw error to allow initialization to continue
  }
};

let db, analytics, functions;

try {
  const initialized = await initializeFirebase();
  db = initialized.db;
  analytics = initialized.analytics;
  functions = initialized.functions;
} catch (error) {
  console.error('Failed to initialize Firebase:', error);
  // You might want to report this to Sentry
  if (window.Sentry) {
    window.Sentry.captureException(error);
  }
}

const processPayment = httpsCallable(functions, 'processPayment');

export { db, analytics, processPayment };

export const logout = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
    console.log("User signed out successfully");
    // You might want to redirect the user or update your app state here
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};
