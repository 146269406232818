import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, Timestamp, onSnapshot, getDoc, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as needed
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { Pencil, Trash2, Save } from 'lucide-react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, setDoc } from 'firebase/firestore';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "./ui/form";
import { useForm } from "react-hook-form";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import LogoutButton from './ui/logout-button';
import { VoucherSendModal } from './ui/voucher-send-modal';
import { useToast } from "./ui/use-toast";


const formatDate = (dateField) => {
  if (!dateField) return 'N/A';
  let date;
  if (dateField instanceof Date) {
    date = dateField;
  } else if (typeof dateField === 'string') {
    date = new Date(dateField);
  } else if (dateField instanceof Timestamp) {
    date = dateField.toDate();
  } else if (dateField.seconds) {
    date = new Date(dateField.seconds * 1000);
  } else {
    return 'Invalid Date';
  }
  
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
};

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const buttonStyle = "bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md";

const getAnnualPassType = (purchase) => {
  const annualPassItem = purchase.items.find(item => item.name.toLowerCase().includes('annual pass'));
  if (annualPassItem) {
    if (annualPassItem.name.includes('-')) {
      // Handle "Annual Pass - Concession" format
      const parts = annualPassItem.name.split('Annual Pass -');
      return parts.length > 1 ? parts[1].trim() : 'Standard';
    } else if (annualPassItem.name.includes('(')) {
      // Handle "Annual Pass (1 Adult & 2 Children)" format
      const match = annualPassItem.name.match(/\((.*?)\)/);
      return match ? match[1].trim() : 'Standard';
    }
  }
  return 'N/A';
};

const safelyConvertToDate = (dateField) => {
  if (!dateField) return null;
  if (dateField instanceof Date) return dateField;
  if (dateField instanceof Timestamp) return dateField.toDate();
  if (typeof dateField === 'string') return new Date(dateField);
  if (dateField.seconds) return new Date(dateField.seconds * 1000);
  return null;
};

function AdminDashboard() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedPurchases, setExpandedPurchases] = useState([]);
  const [annualPassSearchTerm, setAnnualPassSearchTerm] = useState('');
  const [annualPassFilter, setAnnualPassFilter] = useState('all');
  const [salesData, setSalesData] = useState([]);
  const [giftCardData, setGiftCardData] = useState({ redeemed: 0, outstanding: 0 });
  const [annualPassData, setAnnualPassData] = useState({ activated: 0, notActivated: 0 });
  const [productTypeFilter, setProductTypeFilter] = useState('all');
  const [monthFilter, setMonthFilter] = useState('all');
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [isSendingVoucher, setIsSendingVoucher] = useState(false);
  const { toast } = useToast();

  const activateAnnualPass = useCallback(async (purchaseId) => {
    if (!isAdmin) {
      console.log('Not an admin. Skipping annual pass activation.');
      return;
    }

    try {
      const purchaseRef = doc(db, 'transactions', purchaseId);
      const purchaseSnapshot = await getDoc(purchaseRef);
      const purchaseData = purchaseSnapshot.data();

      const now = Timestamp.now();
      await updateDoc(purchaseRef, {
        apActivateDate: now,
      });
      
      console.log(`Annual Pass activated successfully for purchase ${purchaseId}`);

      // Create the product in Lightspeed
      const functions = getFunctions();
      const createAnnualPassProduct = httpsCallable(functions, 'createAnnualPassProduct');
      const result = await createAnnualPassProduct({ redemptionCode: purchaseData.redemptionCode });
      console.log('Lightspeed product created:', result.data);

      // Update the local state to reflect the change
      setPurchases(prevPurchases => {
        const updatedPurchases = prevPurchases.map(p => 
          p.id === purchaseId 
            ? { ...p, apActivateDate: now, lightspeedProductId: result.data.lightspeedProductId } 
            : p
        );
        
        // Re-sort the purchases
        return updatedPurchases.sort((a, b) => {
          const dateA = safelyConvertToDate(a.createdAt) || new Date(0);
          const dateB = safelyConvertToDate(b.createdAt) || new Date(0);
          return dateB - dateA;
        });
      });
    } catch (error) {
      console.error('Error activating Annual Pass:', error);
    }
  }, [isAdmin, setPurchases]);

  const checkAndActivateAnnualPasses = useCallback((purchases) => {
    purchases.forEach(purchase => {
      if (
        purchase.lightspeedGiftCard &&
        purchase.lightspeedGiftCard.balance === 0 &&
        !purchase.apActivateDate &&
        purchase.items.some(item => item.name.toLowerCase().includes('annual pass'))
      ) {
        activateAnnualPass(purchase.id);
      }
    });
  }, [activateAnnualPass]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        });
      } else {
        setIsAdmin(false);
      }
    });

    // Set up a real-time listener for purchases
    const unsubscribePurchases = onSnapshot(collection(db, 'transactions'), (snapshot) => {
      const updatedPurchases = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Sort the purchases by date (most recent first)
      const sortedPurchases = updatedPurchases.sort((a, b) => {
        const dateA = safelyConvertToDate(a.createdAt) || new Date(0);
        const dateB = safelyConvertToDate(b.createdAt) || new Date(0);
        return dateB - dateA;
      });

      setPurchases(sortedPurchases);
      checkAndActivateAnnualPasses(sortedPurchases);
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribe();
      unsubscribePurchases();
    };
  }, [checkAndActivateAnnualPasses]);

  const processSalesData = useCallback((data, timeRange, productTypeFilter) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    const salesByPeriod = data.reduce((acc, transaction) => {
      if (!transaction || typeof transaction !== 'object') {
        return acc;
      }

      let date;
      if (transaction.createdAt instanceof Date) {
        date = transaction.createdAt;
      } else if (transaction.createdAt && transaction.createdAt.seconds) {
        date = new Date(transaction.createdAt.seconds * 1000);
      } else if (typeof transaction.createdAt === 'string') {
        date = new Date(transaction.createdAt);
      } else {
        return acc;
      }

      let key;
      switch (timeRange) {
        case 'all':
          key = date.getFullYear().toString();
          break;
        case 'year_to_date':
          key = date.toLocaleString('default', { month: 'short' });
          break;
        case 'this_month':
          key = date.getDate().toString().padStart(2, '0');
          break;
        default:
          key = date.toISOString().split('T')[0];
      }

      let totalSale = 0;

      if (productTypeFilter === 'all') {
        // If 'all products' is selected, use the total transaction amount
        totalSale = (transaction.amount || 0) / 100; // Convert cents to dollars
      } else if (Array.isArray(transaction.items)) {
        // If a specific product type is selected, sum up the relevant items
        totalSale = transaction.items.reduce((sum, item) => {
          if (!item || typeof item !== 'object') return sum;

          const itemName = (item.name || '').toLowerCase();
          const itemPrice = parseFloat(item.price) || 0;
          const quantity = item.quantity || 1;

          if (
            (productTypeFilter === 'annual pass' && itemName.includes('annual pass')) ||
            (productTypeFilter === 'admission' && (itemName.includes('family pass') || 
              (itemName.includes('adult') && !itemName.includes('annual pass')) ||
              (itemName.includes('concession') && !itemName.includes('annual pass')))) ||
            (productTypeFilter === 'amusement' && (itemName.includes('tractor') || 
              itemName.includes('pony') || itemName.includes('digger')))
          ) {
            return sum + (itemPrice * quantity);
          }

          return sum;
        }, 0);
      }

      acc[key] = (acc[key] || 0) + totalSale;
      return acc;
    }, {});

    return Object.entries(salesByPeriod)
      .map(([date, total]) => ({ date, total: Number(total.toFixed(2)) }))
      .sort((a, b) => {
        if (timeRange === 'year_to_date') {
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          return months.indexOf(a.date) - months.indexOf(b.date);
        }
        return a.date.localeCompare(b.date);
      });
  }, []);

  const processGiftCardData = useCallback((data) => {
    let redeemed = 0;
    let outstanding = 0;
    data.forEach(transaction => {
      if (transaction.lightspeedGiftCard) {
        if (transaction.lightspeedGiftCard.balance === 0) {
          redeemed++;
        } else {
          outstanding++;
        }
      }
    });
    return { redeemed, outstanding };
  }, []);

  const processAnnualPassData = useCallback((data) => {
    let activated = 0;
    let notActivated = 0;
    data.forEach(transaction => {
      if (transaction.items && transaction.items.some(item => item.name.toLowerCase().includes('annual pass'))) {
        if (transaction.apActivateDate) {
          activated++;
        } else {
          notActivated++;
        }
      }
    });
    return { activated, notActivated };
  }, []);

  const fetchSalesData = useCallback(async () => {
    const transactionsRef = collection(db, 'transactions');
    let q = query(transactionsRef);

    const now = new Date();
    let startDate, endDate;

    switch(monthFilter) {
      case 'this_month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'year_to_date':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = now;
        break;
      case 'all':
      default:
        // No date filtering for 'all'
        break;
    }

    if (startDate && endDate) {
      q = query(q, where('createdAt', '>=', startDate), where('createdAt', '<=', endDate));
    }

    const querySnapshot = await getDocs(q);
    let data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if (data && data.length > 0) {
      const processedSalesData = processSalesData(data, monthFilter, productTypeFilter);
      setSalesData(processedSalesData);
    } else {
      setSalesData([]);
    }
  }, [productTypeFilter, monthFilter, processSalesData]);

  const fetchGiftCardData = useCallback(async () => {
    const transactionsRef = collection(db, 'transactions');
    const querySnapshot = await getDocs(transactionsRef);
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Process data for charts
    const processedGiftCardData = processGiftCardData(data);
    setGiftCardData(processedGiftCardData);
  }, [processGiftCardData]);

  const fetchAnnualPassData = useCallback(async () => {
    const transactionsRef = collection(db, 'transactions');
    const querySnapshot = await getDocs(transactionsRef);
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Process data for charts
    const processedAnnualPassData = processAnnualPassData(data);
    setAnnualPassData(processedAnnualPassData);
  }, [processAnnualPassData]);

  useEffect(() => {
    fetchSalesData();
    fetchGiftCardData();
    fetchAnnualPassData();
  }, [fetchSalesData, fetchGiftCardData, fetchAnnualPassData]);

  useEffect(() => {
    fetchProducts();
    fetchPurchases();
  }, [productTypeFilter, monthFilter]);

  const fetchProducts = async () => {
    const querySnapshot = await getDocs(collection(db, 'tickets'));
    setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const fetchPurchases = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'transactions'));
      const purchasesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Sort the purchases by date (most recent first)
      const sortedPurchases = purchasesData.sort((a, b) => {
        const dateA = safelyConvertToDate(a.createdAt) || new Date(0);
        const dateB = safelyConvertToDate(b.createdAt) || new Date(0);
        return dateB - dateA;
      });

      console.log('Fetched and sorted purchases:', sortedPurchases);
      setPurchases(sortedPurchases);
    } catch (error) {
      console.error('Error fetching purchases:', error);
    }
  };

  const handleAddProduct = async (data) => {
    if (data.name && data.price && data.type) {
      const newProduct = {
        name: data.name,
        price: parseFloat(data.price),
        type: data.type,
      };

      if (data.type === 'admission' || data.type === 'annual pass') {
        newProduct.adults = parseInt(data.adults) || 0;
        newProduct.children = parseInt(data.children) || 0;
      }

      await addDoc(collection(db, 'tickets'), newProduct);
      fetchProducts();
    }
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
  };

  const handleSaveEdit = async () => {
    if (editingProduct) {
      await updateDoc(doc(db, 'tickets', editingProduct.id), editingProduct);
      setEditingProduct(null);
      fetchProducts();
    }
  };

  const handleDeleteProduct = async (id) => {
    await deleteDoc(doc(db, 'tickets', id));
    fetchProducts();
  };

  const initiateOAuth = async () => {
    const clientId = process.env.REACT_APP_LIGHTSPEED_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_LIGHTSPEED_REDIRECT_URI);
    const state = generateRandomString(16);
    
    // Store the state in Firestore
    const db = getFirestore();
    await setDoc(doc(db, 'oauthStates', 'current'), { state });

    const authUrl = `https://secure.retail.lightspeed.app/connect?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${encodeURIComponent(state)}`;
    window.location.href = authUrl;
  };

  console.log('Client ID:', process.env.REACT_APP_LIGHTSPEED_CLIENT_ID);
  console.log('Redirect URI:', process.env.REACT_APP_LIGHTSPEED_REDIRECT_URI);

  // Modified function to group and sort products by category
  const groupProductsByCategory = () => {
    const grouped = products.reduce((acc, product) => {
      const category = product.type.toLowerCase();
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(product);
      return acc;
    }, {});

    // Define the desired order of categories
    const orderOfCategories = ['admission', 'annual pass', 'amusement'];

    // Sort the categories based on the defined order
    return Object.fromEntries(
      orderOfCategories
        .filter(category => grouped[category])
        .map(category => [category, grouped[category]])
    );
  };

  const form = useForm({
    defaultValues: {
      name: "",
      price: "",
      type: "",
    },
  });

  const onSubmit = (data) => {
    handleAddProduct(data);
    form.reset();
    setShowAddForm(false);
  };

  const filterPurchases = (purchases, term) => {
    if (!term) return purchases;
    return purchases.filter(purchase => 
      (purchase.customerInfo?.name || '').toLowerCase().includes(term.toLowerCase()) ||
      (purchase.customerInfo?.email || '').toLowerCase().includes(term.toLowerCase()) ||
      (purchase.redemptionCode || '').toLowerCase().includes(term.toLowerCase())
    );
  };

  // Add this function to filter annual passes
  const filterAnnualPasses = (purchases, term, filter) => {
    if (!purchases || !Array.isArray(purchases)) return [];

    let annualPasses = purchases.filter(p => 
      p && p.items && Array.isArray(p.items) && 
      p.items.some(item => item && item.name && item.name.toLowerCase().includes('annual pass'))
    );

    if (term) {
      annualPasses = annualPasses.filter(p => 
        (p.giftRecipient || p.customerInfo?.name || '').toLowerCase().includes(term.toLowerCase()) ||
        getAnnualPassType(p).toLowerCase().includes(term.toLowerCase()) ||
        (p.redemptionCode || '').toLowerCase().includes(term.toLowerCase())
      );
    }

    if (filter !== 'all') {
      annualPasses = annualPasses.filter(p => getAnnualPassStatus(p).toLowerCase() === filter);
    }

    // Sort by activate date (most recent first)
    return annualPasses.sort((a, b) => {
      const dateA = safelyConvertToDate(a.apActivateDate) || new Date(0);
      const dateB = safelyConvertToDate(b.apActivateDate) || new Date(0);
      return dateB - dateA;
    });
  };

  // Add this function to determine annual pass status
  const getAnnualPassStatus = (purchase) => {
    if (!purchase.apActivateDate) return 'Inactive';
    
    const activateDate = safelyConvertToDate(purchase.apActivateDate);
    if (!activateDate) return 'Inactive';

    const expiryDate = new Date(activateDate.setFullYear(activateDate.getFullYear() + 1));
    const now = new Date();

    if (now > expiryDate) return 'Expired';
    return 'Active';
  };

  const createLightspeedWebhook = async () => {
    try {
      const response = await fetch('https://us-central1-farmbarn-a29a7.cloudfunctions.net/createLightspeedWebhook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}. Error: ${errorText}`);
      }
      
      const result = await response.json();
      console.log('Webhook creation result:', result);
      alert('Webhook created successfully!');
    } catch (error) {
      console.error('Error creating webhook:', error);
      alert(`Failed to create webhook. Error: ${error.message}`);
    }
  };

  const handleSendVoucher = async (result) => {
    try {
      setIsSendingVoucher(true);
      toast({
        title: "Success",
        description: "Voucher sent successfully",
      });
      setIsVoucherModalOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to send voucher",
        variant: "destructive",
      });
    } finally {
      setIsSendingVoucher(false);
    }
  };

  const openVoucherModal = (purchase) => {
    setSelectedPurchase(purchase);
    setIsVoucherModalOpen(true);
  };

  if (!isAdmin) {
    return <div>You do not have permission to view this page.</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-green-50 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      
      <Tabs defaultValue="products" className="space-y-4">
        <TabsList>
          <TabsTrigger value="products">Manage Products</TabsTrigger>
          <TabsTrigger value="purchase-history">Purchase History</TabsTrigger>
          <TabsTrigger value="annual-passes">Annual Passes</TabsTrigger>
          <TabsTrigger value="lightspeed">Lightspeed Integration</TabsTrigger>
          <TabsTrigger value="analytics">Analytics</TabsTrigger>
        </TabsList>

        <TabsContent value="products">
          <Card>
            <CardHeader>
              <CardTitle>Manage Products</CardTitle>
              <CardDescription>Add, edit, or delete products</CardDescription>
            </CardHeader>
            <CardContent>
              <Button 
                onClick={() => setShowAddForm(!showAddForm)} 
                className={`mb-4 ${buttonStyle}`}
              >
                {showAddForm ? 'Cancel' : 'Add New Product'}
              </Button>

              {showAddForm && (
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mb-6">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter product name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div className="flex space-x-4"> {/* This div creates a flex container */}
                      <FormField
                        control={form.control}
                        name="price"
                        render={({ field }) => (
                          <FormItem className="flex-1"> {/* flex-1 allows this item to grow */}
                            <FormLabel>Price</FormLabel>
                            <FormControl>
                              <Input type="number" placeholder="Enter price" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="type"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Product Type</FormLabel>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select product type" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="admission">Admission</SelectItem>
                                <SelectItem value="annual pass">Annual Pass</SelectItem>
                                <SelectItem value="amusement">Amusement</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    {(form.watch('type') === 'admission' || form.watch('type') === 'annual pass') && (
                      <>
                        <FormField
                          control={form.control}
                          name="adults"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Number of Adults</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Enter number of adults" {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="children"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Number of Children</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Enter number of children" {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </>
                    )}
                    <Button type="submit" className={buttonStyle}>Add Product</Button>
                  </form>
                </Form>
              )}

              {/* Existing products list */}
              {Object.entries(groupProductsByCategory()).map(([category, categoryProducts]) => (
                <div key={category} className="mt-8">
                  <h3 className="text-xl font-semibold mb-4 capitalize">{category}</h3>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Price</TableHead>
                        {(category === 'admission' || category === 'annual pass') && (
                          <>
                            <TableHead>Adults</TableHead>
                            <TableHead>Children</TableHead>
                          </>
                        )}
                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {categoryProducts.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell>
                            {editingProduct && editingProduct.id === product.id ? (
                              <Input
                                value={editingProduct.name}
                                onChange={(e) => setEditingProduct({ ...editingProduct, name: e.target.value })}
                              />
                            ) : (
                              product.name
                            )}
                          </TableCell>
                          <TableCell>
                            {editingProduct && editingProduct.id === product.id ? (
                              <Input
                                type="number"
                                value={editingProduct.price}
                                onChange={(e) => setEditingProduct({ ...editingProduct, price: parseFloat(e.target.value) })}
                              />
                            ) : (
                              `$${product.price.toFixed(2)}`
                            )}
                          </TableCell>
                          {(category === 'admission' || category === 'annual pass') && (
                            <>
                              <TableCell>
                                {editingProduct && editingProduct.id === product.id ? (
                                  <Input
                                    type="number"
                                    value={editingProduct.adults}
                                    onChange={(e) => setEditingProduct({ ...editingProduct, adults: parseInt(e.target.value) })}
                                  />
                                ) : (
                                  product.adults
                                )}
                              </TableCell>
                              <TableCell>
                                {editingProduct && editingProduct.id === product.id ? (
                                  <Input
                                    type="number"
                                    value={editingProduct.children}
                                    onChange={(e) => setEditingProduct({ ...editingProduct, children: parseInt(e.target.value) })}
                                  />
                                ) : (
                                  product.children
                                )}
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <div className="flex justify-end space-x-2">
                              {editingProduct && editingProduct.id === product.id ? (
                                <Button onClick={handleSaveEdit} className={`${buttonStyle} text-sm`}>
                                  <Save className="mr-2 h-4 w-4" /> Save
                                </Button>
                              ) : (
                                <Button className={`${buttonStyle} text-sm`} onClick={() => handleEditProduct(product)}>
                                  <Pencil className="mr-2 h-4 w-4" /> Edit
                                </Button>
                              )}
                              <Button className={`${buttonStyle} text-sm`} onClick={() => handleDeleteProduct(product.id)}>
                                <Trash2 className="mr-2 h-4 w-4" /> Delete
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ))}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="purchase-history">
          <Card>
            <CardHeader>
              <CardTitle>Purchase History</CardTitle>
              <CardDescription>View recent purchases</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="mb-4">
                <Input
                  type="text"
                  placeholder="Search by customer, email, or redemption code"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full"
                />
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[180px]">Date</TableHead>
                    <TableHead className="w-[150px]">Customer</TableHead>
                    <TableHead className="w-[250px]">Email</TableHead>
                    <TableHead className="w-[150px] text-center">Redemption Code</TableHead>
                    <TableHead className="w-[100px] text-center">Total</TableHead>
                    <TableHead className="w-[100px] text-center">Details</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filterPurchases(purchases, searchTerm).map((purchase) => (
                    <React.Fragment key={purchase.id}>
                      <TableRow>
                        <TableCell className="whitespace-nowrap">{formatDate(purchase.createdAt)}</TableCell>
                        <TableCell>{purchase.customerInfo?.name || 'N/A'}</TableCell>
                        <TableCell className="truncate">{purchase.customerInfo?.email || 'N/A'}</TableCell>
                        <TableCell className="text-center">{purchase.redemptionCode || 'N/A'}</TableCell>
                        <TableCell className="text-right">${((purchase.amount || 0) / 100).toFixed(2)}</TableCell>
                        <TableCell className="text-center">
                          <Button
                            variant="outline"
                            size="sm"
                            className="w-full"
                            onClick={() => setExpandedPurchases(prev => 
                              prev.includes(purchase.id) ? prev.filter(id => id !== purchase.id) : [...prev, purchase.id]
                            )}
                          >
                            {expandedPurchases.includes(purchase.id) ? 'Hide' : 'View'}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandedPurchases.includes(purchase.id) && (
                        <TableRow>
                          <TableCell colSpan={6} className="p-0">
                            <div className="p-4 bg-gray-100">
                              <Table>
                                <TableHeader>
                                  <TableRow>
                                    <TableHead>Item</TableHead>
                                    <TableHead>Quantity</TableHead>
                                    <TableHead>Price</TableHead>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {purchase.items.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{item.name}</TableCell>
                                      <TableCell>{item.quantity}</TableCell>
                                      <TableCell>${item.price.toFixed(2)}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              
                              <div className="mt-4 grid grid-cols-2 gap-4">
                                <div>
                                  <strong>Purchase Type:</strong> {purchase.purchaseType}
                                  {purchase.purchaseType === 'gift' && purchase.giftRecipient && (
                                    <span> (Recipient: {purchase.giftRecipient})</span>
                                  )}
                                </div>
                                <div>
                                  <strong>Expires At:</strong> {purchase.lightspeedGiftCard?.expires_at ? formatDate(purchase.lightspeedGiftCard.expires_at) : 'N/A'}
                                </div>
                                <div>
                                  <strong>Total Redeemed:</strong> ${purchase.lightspeedGiftCard?.total_redeemed?.toFixed(2) || '0.00'}
                                </div>
                                <div>
                                  <strong>Balance:</strong> ${purchase.lightspeedGiftCard?.balance?.toFixed(2) || '0.00'}
                                </div>
                              </div>

                              {/* Buttons added here */}
                              <div className="mt-4 flex justify-end space-x-2">
                                {purchase.items.some(item => item.name.toLowerCase().includes('annual pass')) && (
                                  <Button 
                                    className={buttonStyle}
                                    onClick={() => activateAnnualPass(purchase.id)}
                                    disabled={purchase.apActivateDate !== null}
                                  >
                                    {purchase.apActivateDate ? 'Pass Activated' : 'Activate Annual Pass'}
                                  </Button>
                                )}
                                <Button className={buttonStyle}>
                                  Redeem
                                </Button>
                                <Button 
                                  className={buttonStyle}
                                  onClick={() => openVoucherModal(purchase)}
                                >
                                  Send Voucher
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="annual-passes">
          <Card>
            <CardHeader>
              <CardTitle>Annual Passes</CardTitle>
              <CardDescription>View and manage annual passes</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="mb-4 flex space-x-4">
                <Input
                  type="text"
                  placeholder="Search by customer, pass type, or redemption code"
                  value={annualPassSearchTerm}
                  onChange={(e) => setAnnualPassSearchTerm(e.target.value)}
                  className="w-full"
                />
                <Select value={annualPassFilter} onValueChange={setAnnualPassFilter}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Filter by status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="inactive">Inactive</SelectItem>
                    <SelectItem value="active">Active</SelectItem>
                    <SelectItem value="expired">Expired</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Activate Date</TableHead>
                    <TableHead>Expiry Date</TableHead>
                    <TableHead>Customer</TableHead>
                    <TableHead>Pass Type</TableHead>
                    <TableHead>Redemption Code</TableHead>
                    <TableHead>Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filterAnnualPasses(purchases, annualPassSearchTerm, annualPassFilter)?.map((purchase) => (
                    <TableRow key={purchase.id}>
                      <TableCell>{purchase.apActivateDate ? formatDate(safelyConvertToDate(purchase.apActivateDate)) : 'Not Activated'}</TableCell>
                      <TableCell>
                        {purchase.apActivateDate 
                          ? formatDate(safelyConvertToDate(new Date(safelyConvertToDate(purchase.apActivateDate).setFullYear(safelyConvertToDate(purchase.apActivateDate).getFullYear() + 1))))
                          : 'N/A'}
                      </TableCell>
                      <TableCell>{purchase.giftRecipient || purchase.customerInfo?.name || 'N/A'}</TableCell>
                      <TableCell>{getAnnualPassType(purchase)}</TableCell>
                      <TableCell>{purchase.redemptionCode || 'N/A'}</TableCell>
                      <TableCell>{getAnnualPassStatus(purchase)}</TableCell>
                    </TableRow>
                  )) || <TableRow><TableCell colSpan={6}>No annual passes found.</TableCell></TableRow>}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="lightspeed">
          <Card>
            <CardHeader>
              <CardTitle>Lightspeed Integration</CardTitle>
              <CardDescription>Connect your Lightspeed account and set up webhooks</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Button onClick={initiateOAuth} className={buttonStyle}>
                  Connect Lightspeed Account
                </Button>
                <Button onClick={createLightspeedWebhook} className={buttonStyle}>
                  Create Lightspeed Webhook
                </Button>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="analytics">
          <Card>
            <CardHeader>
              <CardTitle>Sales Analytics</CardTitle>
              <CardDescription>View sales data and statistics</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="mb-4 flex space-x-4">
                <Select value={productTypeFilter} onValueChange={setProductTypeFilter}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Filter by product" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Products</SelectItem>
                    <SelectItem value="Annual Pass">Annual Pass</SelectItem>
                    <SelectItem value="Admission">Admission</SelectItem>
                    <SelectItem value="Amusement">Amusement</SelectItem>
                  </SelectContent>
                </Select>
                <Select value={monthFilter} onValueChange={setMonthFilter}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Filter by time" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Time</SelectItem>
                    <SelectItem value="this_month">This Month</SelectItem>
                    <SelectItem value="year_to_date">Year to Date</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              
              <div className="mb-8">
                <h3 className="text-lg font-semibold mb-2">Total Sales</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={salesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" angle={-45} textAnchor="end" height={70} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div className="grid grid-cols-2 gap-4 mb-8">
                <div>
                  <h3 className="text-lg font-semibold mb-2">Gift Card Status</h3>
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart>
                      <Pie data={[
                        { name: 'Redeemed', value: giftCardData.redeemed },
                        { name: 'Outstanding', value: giftCardData.outstanding }
                      ]} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80}>
                        <Cell fill="#82ca9d" />
                        <Cell fill="#8884d8" />
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2">Annual Pass Status</h3>
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart>
                      <Pie data={[
                        { name: 'Activated', value: annualPassData.activated },
                        { name: 'Not Activated', value: annualPassData.notActivated }
                      ]} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80}>
                        <Cell fill="#ffc658" />
                        <Cell fill="#ff8042" />
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
      <LogoutButton />
      <VoucherSendModal
        isOpen={isVoucherModalOpen}
        onClose={() => setIsVoucherModalOpen(false)}
        onSend={handleSendVoucher}
        redemptionCode={selectedPurchase?.redemptionCode}
        isLoading={isSendingVoucher}
      />
    </div>
  );
}

export default AdminDashboard;