import React, { useState } from 'react'
import { Gift, User } from 'lucide-react'

export function GiftSelector({ onChange, error }) {
  const [selected, setSelected] = useState(null)

  const handleSelect = (value) => {
    setSelected(prev => prev === value ? null : value)
    onChange(selected === value ? null : value)
  }

  return (
    <div>
      <div className="flex rounded-lg overflow-hidden border border-gray-200">
        <button
          className={`flex-1 py-3 px-4 flex items-center justify-center gap-2 transition-colors duration-200 ${
            selected === 'gift' 
              ? 'bg-green-500 text-white font-semibold' 
              : 'bg-white text-gray-700 hover:bg-green-50'
          }`}
          onClick={() => handleSelect('gift')}
        >
          <Gift className="w-5 h-5" />
          <span>Someone else</span>
        </button>
        <button
          className={`flex-1 py-3 px-4 flex items-center justify-center gap-2 transition-colors duration-200 ${
            selected === 'self' 
              ? 'bg-green-500 text-white font-semibold' 
              : 'bg-white text-gray-700 hover:bg-green-50'
          }`}
          onClick={() => handleSelect('self')}
        >
          <User className="w-5 h-5" />
          <span>Yourself</span>
        </button>
      </div>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  )
}
