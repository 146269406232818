import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { browserTracingIntegration, replayIntegration } from "@sentry/browser";
import App from './App';
import './index.css';

Sentry.init({
  dsn: "https://71e971f3b743e389dc3fd0be42d30c04@o4508043317936128.ingest.us.sentry.io/4508043320426496",
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// If you don't have reportWebVitals, comment out or remove this line
// reportWebVitals();
