import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { processPayment } from '../firebase';
import { ErrorBoundary } from 'react-error-boundary';
import { useToast } from "./ui/use-toast";
import { getFunctions, httpsCallable } from "firebase/functions";

function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  )
}

function CheckoutPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [purchaseType, setPurchaseType] = useState('self');
  const [giftRecipient, setGiftRecipient] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const { toast } = useToast();
  const [giftRecipientEmail, setGiftRecipientEmail] = useState('')
  const [giftDeliveryOption, setGiftDeliveryOption] = useState('instantly')
  const [futureDeliveryDate, setFutureDeliveryDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [giftMessage, setGiftMessage] = useState('');

  useEffect(() => {
    if (location.state) {
      const { 
        cartItems, 
        totalPrice, 
        purchaseType, 
        giftRecipient, 
        giftRecipientEmail, 
        giftDeliveryOption, 
        futureDeliveryDate,
        giftMessage // Add this line
      } = location.state;
      setCartItems(cartItems);
      setTotal(totalPrice);
      setPurchaseType(purchaseType);
      setGiftRecipient(giftRecipient);
      setGiftRecipientEmail(giftRecipientEmail);
      setGiftDeliveryOption(giftDeliveryOption);
      setFutureDeliveryDate(futureDeliveryDate ? new Date(futureDeliveryDate) : null);
      setGiftMessage(giftMessage || ''); // Add this line
    }
  }, [location.state]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^04\d{2}\s?\d{3}\s?\d{3}$/;
    return re.test(phone);
  };

  // Add a new function to filter products
  const filterProducts = (products) => {
    return products.map(product => {
      if (product.type === 'admission' || product.type === 'annual pass') {
        // Hide the adults and children fields
        const { adults, children, ...visibleProduct } = product;
        return visibleProduct;
      }
      return product;
    });
  };

  // Use the filtered products in the order summary
  const filteredCartItems = filterProducts(cartItems);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    // Form validation
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name is required";
    if (!email.trim()) newErrors.email = "Email is required";
    else if (!validateEmail(email)) newErrors.email = "Invalid email format";
    if (!phone.trim()) newErrors.phone = "Phone number is required";
    else if (!validatePhone(phone)) newErrors.phone = "Invalid Australian mobile number format (04XX XXX XXX)";
    if (purchaseType === 'gift') {
      if (!giftRecipient.trim()) newErrors.giftRecipient = "Gift recipient name is required";
      if (giftRecipientEmail && !validateEmail(giftRecipientEmail)) {
        newErrors.giftRecipientEmail = "Invalid email format for gift recipient";
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    setPaymentError(null);
    setErrors({});

    try {
      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            country: 'AU',
          }
        }
      });

      if (error) {
        throw new Error(error.message);
      }

      const paymentData = {
        paymentMethodId: paymentMethod.id,
        amount: total * 100, // Convert to cents
        purchaseType,
        giftRecipient: purchaseType === 'gift' ? giftRecipient : null,
        giftMessage: purchaseType === 'gift' ? giftMessage : null, // Add this line
        cartItems,
        customerInfo: {
          name,
          email,
          phone,
        },
        giftRecipientEmail: purchaseType === 'gift' ? giftRecipientEmail : null,
        giftDeliveryOption: purchaseType === 'gift' ? giftDeliveryOption : null,
        futureDeliveryDate: purchaseType === 'gift' && futureDeliveryDate ? futureDeliveryDate.toISOString() : null,
      };

      console.log('Sending payment data:', paymentData);

      const result = await processPayment(paymentData);
      const { clientSecret, redemptionCode, transactionId } = result.data;

      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret);

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      if (paymentIntent.status === 'succeeded') {
        // Payment is successful
        toast({
          title: "Success",
          description: "Purchase completed successfully",
        });

        // Create gift card after successful payment
        console.log('Attempting to create gift card...');
        const functions = getFunctions();
        const createGiftCard = httpsCallable(functions, 'createGiftCard');
        console.log('Calling createGiftCard function...');
        const giftCardResult = await createGiftCard({ 
          transactionId, 
          amount: total * 100,
          // any other necessary data
        });
        console.log('Gift card creation result:', giftCardResult);

        navigate('/payment-confirmation', { 
          state: { redemptionCode, transactionId } 
        });
      } else {
        throw new Error(`Payment failed. Status: ${paymentIntent.status}`);
      }

    } catch (error) {
      console.error("Payment or gift card creation error:", error);
      setPaymentError(error.message || "An error occurred. Please try again.");
      toast({
        title: "Error",
        description: "Failed to complete purchase",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="container mx-auto p-4 bg-green-50 min-h-screen">
        <h1 className="text-4xl font-bold text-center mb-8 text-green-800">Checkout</h1>
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md">
          {/* Customer Information */}
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-4 text-green-700">Your Information</h2>
            <div className="space-y-4">
              <div>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
              </div>
              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>
              <div>
                <Label htmlFor="phone">Mobile Phone Number</Label>
                <Input
                  id="phone"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="04XX XXX XXX"
                  required
                />
                {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
              </div>
            </div>
          </div>

          {/* Order Summary */}
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-4 text-green-700">Order Summary</h2>
            <div className="space-y-2">
              {filteredCartItems.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span>{item.name} x {item.quantity}</span>
                  <span>${(item.price * item.quantity).toFixed(2)}</span>
                </div>
              ))}
              <div className="border-t pt-2 mt-2">
                <div className="flex justify-between font-semibold">
                  <span>Total:</span>
                  <span>${total.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Gift Delivery Information */}
          {purchaseType === 'gift' && (
            <div className="mb-6">
              <h2 className="text-2xl font-semibold mb-4 text-green-700">Gift Delivery</h2>
              <div className="space-y-2">
                <p><strong>Recipient:</strong> {giftRecipient}</p>
                <p><strong>Recipient Email:</strong> {giftRecipientEmail}</p>
                <p><strong>Delivery Option:</strong> {giftDeliveryOption === 'instantly' ? 'Send Immediately' : 'Send on Future Date'}</p>
                {giftDeliveryOption === 'future' && futureDeliveryDate && (
                  <p><strong>Delivery Date:</strong> {futureDeliveryDate instanceof Date ? futureDeliveryDate.toLocaleDateString() : futureDeliveryDate}</p>
                )}
              </div>
            </div>
          )}

          {/* Payment Details */}
          <div className="mb-6">
            <h2 className="text-2xl font-semibold mb-4 text-green-700">Payment Details</h2>
            <div className="mb-4">
              <Label>
                Credit or debit card
                <div className="border rounded-md p-3 mt-1">
                  <CardElement 
                    options={{ 
                      style: { 
                        base: {
                          fontSize: '16px',
                          color: '#32325d',
                        }
                      },
                      hidePostalCode: true // This removes the postal code field
                    }} 
                  />
                </div>
              </Label>
            </div>
          </div>

          <Button 
            onClick={handleSubmit}
            className="w-full mt-4 bg-green-600 hover:bg-green-700 text-white py-3 text-lg"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : `Pay $${total.toFixed(2)}`}
          </Button>
          {paymentError && <div className="text-red-500 mt-2">{paymentError}</div>}
          {isLoading && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-500 mx-auto mb-4"></div>
                <p className="text-lg font-semibold">Processing your payment...</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default CheckoutPage;
