import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "./dialog";
import { Button } from "./button";
import { Input } from "./input";
import { RadioGroup, RadioGroupItem } from "./radio-group";
import { Label } from "./label";

const buttonStyle = "bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md";

export function VoucherSendModal({ 
  isOpen, 
  onClose, 
  onSend,
  redemptionCode,
  isLoading 
}) {
  const [selectedOption, setSelectedOption] = useState('purchaser');
  const [customEmail, setCustomEmail] = useState('');
  const [transactionData, setTransactionData] = useState(null);

  // Fetch transaction data when modal opens
  useEffect(() => {
    async function fetchTransactionData() {
      if (isOpen && redemptionCode) {
        try {
          const transactionsRef = collection(db, 'transactions');
          const q = query(transactionsRef, where('redemptionCode', '==', redemptionCode));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            setTransactionData({ id: doc.id, ...doc.data() });
          }
        } catch (error) {
          console.error('Error fetching transaction:', error);
        }
      }
    }

    fetchTransactionData();
  }, [isOpen, redemptionCode]);

  // Used to get the actual email for validation
  const getSelectedEmail = () => {
    if (!transactionData) return '';
    
    switch (selectedOption) {
      case 'purchaser':
        return transactionData.customerInfo?.email;
      case 'recipient':
        return transactionData.giftRecipientEmail;
      case 'custom':
        return customEmail;
      default:
        return '';
    }
  };

  // Used to send the email type to the backend
  const getSelectedEmailData = () => {
    if (!transactionData) return null;

    switch (selectedOption) {
      case 'purchaser':
        return { 
          emailType: 'purchaser',
          redemptionCode: redemptionCode
        };
      case 'recipient':
        return { 
          emailType: 'recipient',
          redemptionCode: redemptionCode
        };
      case 'custom':
        return { 
          emailType: 'custom',
          customEmail: customEmail,
          redemptionCode: redemptionCode
        };
      default:
        return null;
    }
  };

  // Update the onSend handler
  const handleSend = async (emailData) => {
    try {
      const functions = getFunctions();
      const resendVoucher = httpsCallable(functions, 'resendVoucherEmail');
      const result = await resendVoucher(emailData);
      onSend(result.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Send Voucher</DialogTitle>
          <DialogDescription>
            Choose where to send the voucher
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <RadioGroup
            defaultValue="purchaser"
            onValueChange={setSelectedOption}
            className="grid gap-4"
          >
            {/* Purchaser email option - always shown */}
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="purchaser" id="purchaser" />
              <Label htmlFor="purchaser">
                Purchaser ({transactionData?.customerInfo?.email || 'Loading...'})
              </Label>
            </div>

            {/* Gift recipient email option - only shown if giftRecipientEmail exists */}
            {transactionData?.giftRecipientEmail && (
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="recipient" id="recipient" />
                <Label htmlFor="recipient">
                  Gift Recipient ({transactionData.giftRecipientEmail})
                </Label>
              </div>
            )}

            {/* Custom email option - always shown */}
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="custom" id="custom" />
              <Label htmlFor="custom">Custom Email</Label>
            </div>
          </RadioGroup>
          
          {selectedOption === 'custom' && (
            <Input
              type="email"
              placeholder="Enter email address"
              value={customEmail}
              onChange={(e) => setCustomEmail(e.target.value)}
              className="mt-2"
            />
          )}
        </div>
        <div className="flex justify-end space-x-2">
          <Button onClick={onClose} className={buttonStyle}>
            Cancel
          </Button>
          <Button 
            onClick={() => handleSend(getSelectedEmailData())}
            disabled={!getSelectedEmail() || isLoading}
            className={buttonStyle}
          >
            {isLoading ? "Sending..." : "Send Voucher"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
