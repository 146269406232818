import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function ProtectedRoute({ children }) {
  const { currentUser, isAdmin } = useAuth();
  const location = useLocation();

  console.log('ProtectedRoute - currentUser:', currentUser);
  console.log('ProtectedRoute - isAdmin:', isAdmin);

  if (!currentUser) {
    console.log('ProtectedRoute - Redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isAdmin) {
    console.log('ProtectedRoute - Redirecting to home (not admin)');
    return <Navigate to="/" replace />;
  }

  console.log('ProtectedRoute - Rendering admin content');
  return children;
}

export default ProtectedRoute;
