'use client'

import React, { useState, useEffect, useCallback } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion"
import { Button } from "./ui/button"
import { PawPrint, Ticket, CalendarDays } from 'lucide-react'
import { useNavigate } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { Input } from "./ui/input"
import { GiftSelector } from './ui/gift-selector'
import GiftCardSender from './ui/gift-card-sender'

/**
 * @typedef {Object} Product
 * @property {string} id
 * @property {string} name
 * @property {number} price
 * @property {number} quantity
 */

/**
 * @typedef {Object} Category
 * @property {string} id
 * @property {string} name
 * @property {React.ReactNode} icon
 * @property {Product[]} products
 */

const NoProductsMessage = () => (
  <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 my-4">
    <p className="text-yellow-700 font-medium">
      Select the number of adults and children above to see available options
    </p>
  </div>
);

export default function Ticketsv2({ updateCart }) {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [adultFilter, setAdultFilter] = useState(0);
  const [childFilter, setChildFilter] = useState(0);
  const [openItems, setOpenItems] = useState([]);
  const [purchaseType, setPurchaseType] = useState(null);
  const [giftRecipient, setGiftRecipient] = useState('');
  const [giftMessage, setGiftMessage] = useState('');
  const [giftRecipientEmail, setGiftRecipientEmail] = useState('')
  const [giftDeliveryOption, setGiftDeliveryOption] = useState('instantly')
  const [futureDeliveryDate, setFutureDeliveryDate] = useState(null)
  const [errors, setErrors] = useState({});
  const [checkoutError, setCheckoutError] = useState(null);

  const fetchProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, 'tickets'));
      
      if (!querySnapshot || !querySnapshot.docs) {
        throw new Error('Failed to load products data');
      }

      const productsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        if (!data.type || !data.name || typeof data.price === 'undefined') {
          console.error(`Invalid product data for document ${doc.id}:`, data);
          return null;
        }
        return {
          id: doc.id,
          ...data,
          quantity: 0
        };
      }).filter(Boolean);

      if (productsData.length === 0) {
        throw new Error('No valid products found');
      }

      // Define category mapping
      const categoryMapping = {
        'amusement': 'Amusements',
        'annual pass': 'Annual Passes',
        'admission': 'Admission'
      };

      // Group products by category
      const groupedProducts = productsData.reduce((acc, product) => {
        const category = categoryMapping[product.type.toLowerCase()] || product.type;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(product);
        return acc;
      }, {});

      // Create categories array
      const categoriesData = Object.keys(groupedProducts).map(categoryName => ({
        id: categoryName.toLowerCase().replace(/\s+/g, '-'),
        name: categoryName,
        icon: getCategoryIcon(categoryName),
        products: groupedProducts[categoryName]
      }));

      setCategories(categoriesData);
      setOpenItems(categoriesData.map(category => category.id));
    } catch (error) {
      console.error('Error fetching products:', error);
      setError(error.message || 'Failed to load products. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const getCategoryIcon = (categoryName) => {
    switch(categoryName.toLowerCase()) {
      case 'admission':
        return <PawPrint className="h-6 w-6" />;
      case 'annual passes':
        return <CalendarDays className="h-6 w-6" />;
      case 'amusements':
        return <Ticket className="h-6 w-6" />;
      default:
        return <Ticket className="h-6 w-6" />;
    }
  };

  const updateQuantity = (categoryId, productId, newQuantity) => {
    setCategories(prevCategories =>
      prevCategories.map(category => {
        if (category.id === categoryId) {
          return {
            ...category,
            products: category.products.map(product => {
              if (product.id === productId) {
                return { ...product, quantity: Math.max(0, isNaN(newQuantity) ? 0 : newQuantity) }
              }
              return product
            }),
          }
        }
        return category
      })
    )
  }

  const cartItems = categories.flatMap(category =>
    category.products.filter(product => product.quantity > 0)
  )

  const totalPrice = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0)

  useEffect(() => {
    const cartItems = categories.flatMap(category =>
      category.products.filter(product => product.quantity > 0)
    );
    
    updateCart(cartItems);
  }, [categories, updateCart]);

  const handleCheckout = () => {
    // Reset errors
    setErrors({});
    setCheckoutError(null);

    // Validate fields
    let newErrors = {};

    // Check if purchase type is selected
    if (purchaseType === null) {
      newErrors.purchaseType = "Please select whether this purchase is for yourself or someone else";
    }

    if (purchaseType === 'gift') {
      if (!giftRecipient.trim()) {
        newErrors.giftRecipient = "Recipient name is required";
      }

      // Make email required
      if (!giftRecipientEmail.trim()) {
        newErrors.giftRecipientEmail = "Recipient email is required";
      } else if (!validateEmail(giftRecipientEmail)) {
        newErrors.giftRecipientEmail = "Invalid email format";
      }
    }

    // If there are errors, show them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setCheckoutError("Please complete the required fields above");
      return;
    }

    let formattedFutureDeliveryDate = null;
    if (giftDeliveryOption === 'future' && futureDeliveryDate) {
      formattedFutureDeliveryDate = new Date(futureDeliveryDate).toISOString();
    }

    const checkoutData = {
      cartItems,
      totalPrice,
      purchaseType,
      giftRecipient: purchaseType === 'gift' ? giftRecipient : null,
      giftRecipientEmail,
      giftDeliveryOption,
      futureDeliveryDate: formattedFutureDeliveryDate,
      giftMessage: purchaseType === 'gift' ? giftMessage : null,
    };
    
    // Navigate to checkout or call the checkout function
    navigate('/checkout', { state: checkoutData });
  };

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const getCategoryInstructions = (categoryName) => {
    switch(categoryName.toLowerCase()) {
      case 'admission':
        return "Select the appropriate ticket. You can add additional adults or concession tickets if the family passes listed don't fit your requirements.";
      case 'annual passes':
        return "Select the appropriate annual pass. You can add additional adults or concession tickets if the options listed don't fit your requirements.";
      case 'amusements':
        return "If you want to purchase amusement tickets in advance, you can select the amusements here. Note that if you are unsure, you can easily add these at the counter when you arrive at the Farm Barn.";
      default:
        return "";
    }
  };

  const filterProducts = (products) => {
    return products.filter(product => {
      if (product.type === 'admission' || product.type === 'annual pass') {
        // If no adults and no children are selected, return an empty array for admission and annual passes
        if (adultFilter === 0 && childFilter === 0) {
          return false;
        }

        // Case 1: Always show products with 1 adult and 0 children if no children are selected
        if (childFilter === 0 && product.adults === 1 && product.children === 0) {
          return true;
        }
        
        // Case 2: For more than 2 adults, show matching products for 2 adults and products for 1 adult
        if (adultFilter > 2) {
          if ((product.adults === 2 && product.children === childFilter) ||
              (product.adults === 1 && product.children === 0)) {
            return true;
          }
        }
        
        // Default case: exact match
        return product.adults === adultFilter && product.children === childFilter;
      }
      return true; // Always keep all amusement products
    });
  };

  const handleDateChange = (date) => {
    setFutureDeliveryDate(date)
  }

  const handleRecipientNameChange = (name) => {
    setGiftRecipient(name);
  };

  const handleMessageChange = (message) => {
    setGiftMessage(message);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 bg-green-50 min-h-screen">
      
      {/* Group size section */}
      <div className="mb-6 bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-2 text-green-700">How Many People In Your Group?</h2>
        <p className="text-sm text-gray-600 mb-4 italic">
          Select the number of adults and children and then select the appropriate products below.

        </p>
        <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <div>
            <label htmlFor="adultFilter" className="block text-sm font-medium text-gray-700 mb-1">Number of Adults</label>
            <div className="flex items-center space-x-1">
              <Button
                size="sm"
                variant="outline"
                onClick={() => setAdultFilter(Math.max(0, adultFilter - 1))}
                className="bg-white border border-gray-300 rounded-md h-9 w-9 flex items-center justify-center focus:outline-none"
              >
                -
              </Button>
              <Input
                id="adultFilter"
                type="number"
                min="0"
                value={adultFilter}
                onChange={(e) => setAdultFilter(Math.max(0, parseInt(e.target.value) || 0))}
                className="bg-white w-12 h-9 text-center border border-gray-300 rounded-md px-2 focus:outline-none"
              />
              <Button
                size="sm"
                variant="outline"
                onClick={() => setAdultFilter(adultFilter + 1)}
                className="bg-white border border-gray-300 rounded-md h-9 w-9 flex items-center justify-center focus:outline-none"
              >
                +
              </Button>
            </div>
          </div>
          <div>
            <label htmlFor="childFilter" className="block text-sm font-medium text-gray-700 mb-1">Number of Children</label>
            <div className="flex items-center space-x-1">
              <Button
                size="sm"
                variant="outline"
                onClick={() => setChildFilter(Math.max(0, childFilter - 1))}
                className="bg-white border border-gray-300 rounded-md h-9 w-9 flex items-center justify-center focus:outline-none"
              >
                -
              </Button>
              <Input
                id="childFilter"
                type="number"
                min="0"
                value={childFilter}
                onChange={(e) => setChildFilter(Math.max(0, parseInt(e.target.value) || 0))}
                className="bg-white w-12 h-9 text-center border border-gray-300 rounded-md px-2 focus:outline-none"
              />
              <Button
                size="sm"
                variant="outline"
                onClick={() => setChildFilter(childFilter + 1)}
                className="bg-white border border-gray-300 rounded-md h-9 w-9 flex items-center justify-center focus:outline-none"
              >
                +
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Error message above Your Cart */}
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 p-4 my-4">
          <p className="text-red-700">
            {error}
          </p>
          <Button 
            onClick={() => {
              setError(null);
              fetchProducts();
            }}
            className="mt-2"
          >
            Try Again
          </Button>
        </div>
      )}
      
      {/* Ticket selection area */}
      <div className="mb-8">
        <Accordion 
          type="multiple" 
          value={openItems} 
          onValueChange={setOpenItems}
          className="w-full"
        >
          {categories
            .sort((a, b) => {
              const order = ['Admission', 'Annual Passes', 'Amusements'];
              return order.indexOf(a.name) - order.indexOf(b.name);
            })
            .map((category) => (
              <AccordionItem key={category.id} value={category.id}>
                <AccordionTrigger className="text-xl font-semibold text-green-700">
                  <div className="flex items-center gap-2">
                    {category.icon}
                    {category.name}
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="space-y-4">
                    <p className="text-sm text-gray-600 mb-4 italic">
                      {getCategoryInstructions(category.name)}
                    </p>
                    {(category.name === 'Admission' || category.name === 'Annual Passes') && 
                     adultFilter === 0 && childFilter === 0 ? (
                      <NoProductsMessage />
                    ) : (
                      filterProducts(category.products).map((product) => (
                        <div key={product.id} className="flex items-center justify-between">
                          <span className="text-lg text-green-600">
                            {product.name} - ${product.price.toFixed(2)}
                            {product.price === 0 && " (Free)"}
                          </span>
                          <div className="flex items-center space-x-1">
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={() => updateQuantity(category.id, product.id, product.quantity - 1)}
                              className="bg-white border border-gray-300 rounded-md h-9 w-9 flex items-center justify-center focus:outline-none"
                            >
                              -
                            </Button>
                            <Input
                              type="number"
                              min="0"
                              value={product.quantity}
                              onChange={(e) => updateQuantity(category.id, product.id, parseInt(e.target.value, 10))}
                              className="bg-white w-12 h-9 text-center border border-gray-300 rounded-md px-2 focus:outline-none"
                            />
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={() => updateQuantity(category.id, product.id, product.quantity + 1)}
                              className="bg-white border border-gray-300 rounded-md h-9 w-9 flex items-center justify-center focus:outline-none"
                            >
                              +
                            </Button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
        </Accordion>
      </div>
      
      {/* Gift selector section */}
      <div className="mb-6 bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-2 text-green-700">Gift Card or Purchasing for Yourself?</h2>
        <div className="text-sm text-gray-600 mb-4 italic">
          Select an option below. If you are purchasing a gift card, you can add a message and email address below. You can choose to send the voucher now or at a later date.
        </div>
        
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 my-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <span className="text-sm text-blue-700">
                <span className="font-medium">Note:</span> You can enter your email address if you'd prefer to print the voucher at home.
              </span>
            </div>
          </div>
        </div>

        <GiftSelector
          onChange={(value) => setPurchaseType(value)}
          error={errors.purchaseType}
        />
      </div>

      {purchaseType === 'gift' && (
        <div className="mb-6 bg-white p-4 rounded-lg shadow-md">
          <GiftCardSender
            onEmailChange={(email) => setGiftRecipientEmail(email)}
            onSendOptionChange={(option) => setGiftDeliveryOption(option)}
            onDateChange={handleDateChange}
            onRecipientNameChange={handleRecipientNameChange}
            onMessageChange={handleMessageChange}
            errors={errors}
          />
        </div>
      )}
      
      {/* Error message above Your Cart */}
      {checkoutError && (
        <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
          <p className="font-bold">Error</p>
          <p>{checkoutError}</p>
        </div>
      )}
      
      {/* Your Cart section */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-green-700">Your Cart</h2>
        {cartItems.length === 0 ? (
          <p className="text-gray-500">Your cart is empty</p>
        ) : (
          <div className="space-y-2">
            {cartItems.map((item) => (
              <div key={item.id} className="flex justify-between">
                <span>
                  {item.name} x {item.quantity}
                </span>
                <span>${(item.price * item.quantity).toFixed(2)}</span>
              </div>
            ))}
            <div className="border-t pt-2 mt-2">
              <div className="flex justify-between font-semibold">
                <span>Total:</span>
                <span>${totalPrice.toFixed(2)}</span>
              </div>
            </div>
          </div>
        )}
        <Button 
          className="w-full mt-4 bg-green-600 hover:bg-green-700 text-white py-3 text-lg"
          disabled={cartItems.length === 0}
          onClick={handleCheckout}
        >
          <Ticket className="mr-2 h-5 w-5 text-white" /> Checkout
        </Button>
      </div>
    </div>
  )
}