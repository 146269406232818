import React from 'react';
import { logout } from '../../firebase';

const LogoutButton = () => {
  const handleLogout = async () => {
    await logout();
    // You might want to redirect the user after logout
    // For example: history.push('/login');
  };

  return (
    <button onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;
