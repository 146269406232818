import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import Ticketsv2 from './components/Ticketsv2';
import CheckoutPage from './components/CheckoutPage';
import PaymentConfirmationPage from './components/PaymentConfirmationPage';
import ProtectedRoute from './components/ProtectedRoute';
import AdminDashboard from './components/AdminDashboard';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
import { ToastProvider, ToastViewport } from './components/ui/toast';
import { stripePromise } from './lib/stripe';

function App() {
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);

  const updateCart = useCallback((items) => {
    setCartItems(items);
    setTotal(items.reduce((sum, item) => sum + item.price * item.quantity, 0));
  }, []);

  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <ToastProvider>
          <Router>
            <div className="App">
              <Routes>
                <Route path="/" element={
                  <Ticketsv2 updateCart={updateCart} />
                } />
                <Route path="/checkout" element={
                  <CheckoutPage cartItems={cartItems} total={total} />
                } />
                <Route path="/payment-confirmation" element={<PaymentConfirmationPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route 
                  path="/admin" 
                  element={
                    <ProtectedRoute>
                      <AdminDashboard />
                    </ProtectedRoute>
                  } 
                />
                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
          </Router>
          <ToastViewport />
        </ToastProvider>
      </Elements>
    </AuthProvider>
  );
}

export default App;
