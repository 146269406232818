import React, { useState, useEffect, useRef } from 'react'
import { Clock, Send, Calendar as CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react"
import { format, addDays, startOfTomorrow, isBefore, startOfDay, addMonths } from "date-fns"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import { Input } from "./input"
import { Textarea } from "./textarea"
import { Button } from "./button"

export default function GiftCardSender({ 
  onEmailChange, 
  onSendOptionChange, 
  onDateChange,
  onRecipientNameChange,
  onMessageChange,
  errors
}) {
  const [email, setEmail] = useState('')
  const [sendOption, setSendOption] = useState('instantly')
  const [date, setDate] = useState(startOfTomorrow())
  const [time, setTime] = useState('12:00') // Default time set to 12:00 PM
  const [recipientName, setRecipientName] = useState('')
  const [message, setMessage] = useState('')
  const timeInputRef = useRef(null)
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [emailError, setEmailError] = useState('')

  useEffect(() => {
    if (date && time) {
      const dateTime = new Date(date)
      const [hours, minutes] = time.split(':')
      dateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10))
      
      // Ensure the selected date and time is in the future
      const now = new Date()
      if (dateTime <= now) {
        dateTime.setDate(dateTime.getDate() + 1)
      }
      
      onDateChange(dateTime.toISOString())
    }
  }, [date, time, onDateChange])

  const handleSendOptionChange = (option) => {
    setSendOption(option)
    onSendOptionChange(option)
  }

  const handleTimeChange = (e) => {
    const newTime = e.target.value
    setTime(newTime)
    
    // Ensure the selected time is in the future
    const selectedDateTime = new Date(date)
    const [hours, minutes] = newTime.split(':')
    selectedDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10))
    
    const now = new Date()
    if (selectedDateTime <= now) {
      setDate(addDays(date, 1))
    }
  }

  const handleTimeInputClick = () => {
    timeInputRef.current.showPicker()
  }

  const handleRecipientNameChange = (e) => {
    setRecipientName(e.target.value)
    onRecipientNameChange(e.target.value)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
    onMessageChange(e.target.value)
  }

  const disabledDays = (date) => {
    return isBefore(date, startOfDay(new Date()))
  }

  const handlePreviousMonth = () => {
    setCurrentMonth(prevMonth => addMonths(prevMonth, -1))
  }

  const handleNextMonth = () => {
    setCurrentMonth(prevMonth => addMonths(prevMonth, 1))
  }

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    
    if (newEmail && !validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
    
    onEmailChange(newEmail);
  };

  return (
    <div className="space-y-4">
      <div>
        <Input
          type="text"
          value={recipientName}
          onChange={handleRecipientNameChange}
          placeholder="Name of recipient (required)"
          className={`w-full ${errors.giftRecipient ? 'border-red-500' : ''}`}
        />
        {errors.giftRecipient && <p className="text-red-500 text-sm mt-1">{errors.giftRecipient}</p>}
      </div>
      <div>
        <Input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter recipient's email (required)"
          className={`w-full ${emailError || errors.giftRecipientEmail ? 'border-red-500' : ''}`}
          required
        />
        {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
        {errors.giftRecipientEmail && <p className="text-red-500 text-sm mt-1">{errors.giftRecipientEmail}</p>}
      </div>
      <div>
        <Textarea
          value={message}
          onChange={handleMessageChange}
          placeholder="Message to recipient (optional)"
          className="w-full"
        />
      </div>
      <div>
        <div className="flex rounded-lg overflow-hidden border border-gray-200">
          <button
            onClick={() => handleSendOptionChange('instantly')}
            className={`flex-1 py-3 px-4 flex items-center justify-center gap-2 transition-colors duration-200 ${
              sendOption === 'instantly' 
                ? 'bg-green-500 text-white font-semibold' 
                : 'bg-white text-gray-700 hover:bg-green-50'
            }`}
          >
            <Send className="w-5 h-5" />
            <span>Send instantly</span>
          </button>
          <button
            onClick={() => handleSendOptionChange('future')}
            className={`flex-1 py-3 px-4 flex items-center justify-center gap-2 transition-colors duration-200 ${
              sendOption === 'future' 
                ? 'bg-green-500 text-white font-semibold' 
                : 'bg-white text-gray-700 hover:bg-green-50'
            }`}
          >
            <Clock className="w-5 h-5" />
            <span>Send later</span>
          </button>
        </div>
      </div>
      {sendOption === 'future' && (
        <div className="flex space-x-4">
          <div className="flex-1 relative">
            <Popover>
              <PopoverTrigger asChild>
                <button
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 text-left"
                >
                  {date ? format(date, "MMMM do, yyyy") : "Pick a date"}
                  <CalendarIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                </button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <div className="p-2">
                  <div className="flex justify-between items-center mb-2">
                    <Button
                      onClick={handlePreviousMonth}
                      variant="outline"
                      size="icon"
                      className="h-7 w-7"
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <div className="font-semibold">
                      {format(currentMonth, 'MMMM yyyy')}
                    </div>
                    <Button
                      onClick={handleNextMonth}
                      variant="outline"
                      size="icon"
                      className="h-7 w-7"
                    >
                      <ChevronRight className="h-4 w-4" />
                    </Button>
                  </div>
                  <style>
                    {`
                      .rdp-month_caption,
                      .rdp-nav,
                      nav.space-x-1.flex.items-center {
                        display: none !important;
                      }
                    `}
                  </style>
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    disabled={disabledDays}
                    initialFocus
                    modifiers={{
                      disabled: disabledDays
                    }}
                    modifiersStyles={{
                      disabled: { color: "gray", opacity: 0.5 }
                    }}
                    month={currentMonth}
                    onMonthChange={setCurrentMonth}
                    className="rounded-md border"
                    showOutsideDays={false}
                    fixedWeeks
                  />
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex-1 relative">
            <div className="relative">
              <input
                ref={timeInputRef}
                type="time"
                value={time}
                onChange={handleTimeChange}
                className="sr-only"
                style={{ colorScheme: 'light' }}
              />
              <button
                onClick={handleTimeInputClick}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 text-left"
              >
                {time}
                <Clock className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
